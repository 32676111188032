<template>
  <main>
    <Row :gutter="15">
      <Col :span="17">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button icon="md-add-circle" @click="create" v-if="Auth([ 'University', 'create' ])">添加</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
export default {
  data() {
    return {
      searches: {
        name: "学校名称"
      },
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "学校",
          key: "name",
          sortable: "custom",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h(
              "div",
              { class: "flex flex-justify-center flex-align-center" },
              [
                h("img", {
                  attrs: {
                    src: params.row.logo ? params.row.logo : ""
                  },
                  class: "cover mr"
                }),
                h("div", [h("p", params.row.name)])
              ]
            );
          }
        },
        {
          title: "专业数量",
          key: "majors_count",
          sortable: "custom",
          align: "center",
          minWidth: 120,
          render: (h, params) => {
            return h(
              "a",
              {
                class: "text-center",
                on: {
                  click: () => {
                    this.$router.push("/university-major/" + params.row.id);
                  }
                }
              },
              [h("p", params.row.majors_count)]
            );
          }
        },

        {
          title: "添加时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["University", "update"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "success",
                    size: "small",
                    icon: "md-create"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.update(params.row);
                    }
                  }
                })
              );
            }

            // if (this.Auth(["MemberCardChannel", "delete"])) {
            //   html.push(
            //     h("Button", {
            //       props: {
            //         type: "error",
            //         size: "small",
            //         icon: "md-trash"
            //       },
            //       class: "mg-lr-xs",
            //       on: {
            //         click: () => {
            //           this.remove(params.row);
            //         }
            //       }
            //     })
            //   );
            // }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/university/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    create() {
      this.$router.push("/university/create");
    },
    update(item) {
      this.$router.push("/university/update/" + item.id);
    },
    remove(item) {
      this.$Modal.confirm({
        title: "确定要删除该学校吗？",
        content: item.name,
        onOk: () => {
          this.$http.post("/university/delete/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    }
  }
};
</script>